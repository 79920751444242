import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import maintainLottie from 'src/asset/lottie/maintain.json';

// Pages

// Utilities
import { useScrollToTop } from 'src/hooks/useScrollToTop';
import localization from 'src/localization';
import { IP_ADDRESS_KEY } from 'src/utils/constants/config';

// Components
import dayjs from 'dayjs';
import numeral from 'numeral';
import Lottie from 'react-lottie';
import analytics from 'src/analytics';
import SmallScreenNotice from 'src/components/common/SmallScreenNotice';
import useCountDown from 'src/hooks/useCountDown';
import { useFormSearch } from 'src/zustand/useFormSearch';

function App() {
  localization.init();
  useScrollToTop();

  const startDate = dayjs();
  const endDate = dayjs('2024-10-16');
  const secondsDiff = endDate.diff(startDate, 'second');

  const { timeLeft } = useCountDown(secondsDiff, true);

  const days = numeral(Math.floor(timeLeft / (3600 * 24))).format('00');
  const hours = numeral(Math.floor((timeLeft - Number(days) * 3600 * 24) / 3600)).format('00');
  const minutes = numeral(Math.floor((timeLeft % 3600) / 60)).format('00');
  const seconds = numeral(Math.floor(timeLeft % 60)).format('00');

  analytics.initialize();

  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const ipAddress = localStorage.getItem(IP_ADDRESS_KEY);
  const { type, visible, selectedCompanyCompare, handleCloseSearch } = useFormSearch((state) => state);

  // Get Client's IP Address
  async function getIPFromAmazon() {
    setLoading(true);
    await fetch('https://checkip.amazonaws.com/')
      .then((res) => res.text())
      .then((data) => {
        localStorage.setItem(IP_ADDRESS_KEY, data);
        return data;
      });
    setLoading(false);
  }

  useEffect(() => {
    if (!ipAddress) getIPFromAmazon();
  }, [ipAddress]);

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));

  if (isSmallScreen) {
    return <SmallScreenNotice />;
  }

  return (
    <Box
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
      width={'100%'}
      sx={{
        minHeight: '100dvh',
        overflow: 'hidden',
      }}
      bgcolor={theme.palette.background.paper1}
    >
      <Stack spacing={3} alignItems={'center'}>
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: maintainLottie,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice',
            },
          }}
          height={467}
          width={622}
        />
        <Box textAlign={'center'}>
          <Typography color={'#3658C1'} variant="h4">
            Sorry ! We Are Currently Down For Maintenance
          </Typography>
          <Typography color={'#3658C1'} variant="h4">
            But Don't Worry We Will Be Up In
          </Typography>
        </Box>
        <Stack spacing={6} direction={'row'} alignItems={'center'}>
          <Stack alignItems={'center'}>
            <Typography fontWeight={900} variant="h2" color={'secondary.main'}>
              {days}
            </Typography>
            <Typography variant="h6" color={'#94A3B8'}>
              Days
            </Typography>
          </Stack>
          <Stack alignItems={'center'}>
            <Typography fontWeight={900} variant="h2" color={'secondary.main'}>
              {hours}
            </Typography>
            <Typography variant="h6" color={'#94A3B8'}>
              Hours
            </Typography>
          </Stack>
          <Stack alignItems={'center'}>
            <Typography fontWeight={900} variant="h2" color={'secondary.main'}>
              {minutes}
            </Typography>
            <Typography variant="h6" color={'#94A3B8'}>
              Minutes
            </Typography>
          </Stack>
          <Stack alignItems={'center'}>
            <Typography fontWeight={900} variant="h2" color={'secondary.main'}>
              {seconds}
            </Typography>
            <Typography variant="h6" color={'#94A3B8'}>
              Seconds
            </Typography>
          </Stack>
        </Stack>
        <Typography lineHeight={'116px'} fontWeight={900} fontSize={145} color={'rgba(148, 163, 184, 0.10)'}>
          COMING SOON
        </Typography>
      </Stack>
    </Box>
  );

  // return (
  //   <React.Fragment>
  //     <LocalizationProvider dateAdapter={AdapterDayjs}>
  //       <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
  //         <CircularProgress color="inherit" />
  //       </Backdrop>
  //       <FormSearchCompany open={type === FormType.SearchCompany && visible} handleClose={handleCloseSearch} />
  //       <FormSearchPeople open={type === FormType.SearchPeople && visible} handleClose={handleCloseSearch} />
  //       <FormSearchTradeData open={type === FormType.SearchTradeData && visible} handleClose={handleCloseSearch} />
  //       <FormCompareCompany
  //         open={type === FormType.CompareCompany && visible}
  //         handleClose={handleCloseSearch}
  //         selectedCompanyCompare={selectedCompanyCompare}
  //       />
  //       <WarningSensitiveImage
  //         open={type === FormType.WarningSensitiveImage && visible}
  //         handleClose={handleCloseSearch}
  //       />
  //       <Routes>
  //         {NoAuthRoutes.map((route) => route)}
  //         {AuthRoutes.map((route) => route)}
  //         <Route path="/*" element={<NotFoundPage />} />
  //       </Routes>
  //     </LocalizationProvider>
  //   </React.Fragment>
  // );
}

export default App;
